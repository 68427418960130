import React from 'react'
import styled from 'react-emotion'
import Helmet from 'react-helmet'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'

import Layout from '../components/layout'
import { rhythm, scale } from '../utils/typography'

import TagList from './../components/tagList'
import { COLOR_PALLETE } from '../utils/colors'
import Share from '../components/share'

const BlogInfo = styled('div')`
  display: flex;
  @media (max-width: 500px) {
    flex-direction: column;
  }
`
class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')
    const siteDescription = post.excerpt
    const { previous, next, slug } = this.props.pageContext
    const siteUrl = get(this.props, 'data.site.siteMetadata.siteUrl')
    const imageUrl = `${siteUrl}${
      post.frontmatter.cover.childImageSharp.resolutions.src
    }`
    const tags = post.frontmatter.tags
    const currentPostUrl = `${siteUrl}${slug}`
    return (
      <Layout location={this.props.location}>
        <Helmet
          htmlAttributes={{ lang: 'en' }}
          meta={[
            { name: 'description', content: siteDescription },
            { name: 'twitter:card', content: 'summary' },
            { name: 'twitter:site', content: '@kartik2406' },
            { name: 'twitter:title', content: post.frontmatter.title },
            { name: 'twitter:description', content: post.excerpt },
            {
              name: 'twitter:image',
              content: imageUrl,
            },
            {
              name: 'og:title',
              content: post.frontmatter.title,
            },
            {
              name: 'og:type',
              content: 'article',
            },
            {
              name: 'og:image',
              content: imageUrl,
            },
            {
              name: 'og:url',
              content: currentPostUrl,
            },
          ]}
          title={`${post.frontmatter.title} | ${siteTitle}`}
        />
        <h1 style={{ marginBottom: '0.75rem' }}>{post.frontmatter.title}</h1>
        <BlogInfo>
          <div style={{ display: 'flex', marginTop: '3px' }}>
            <p
              style={{
                ...scale(-1 / 5),
                display: 'block',
                marginBottom: 0,
                marginTop: 0,
                color: COLOR_PALLETE.LIGHTER_BLACK,
              }}
            >
              {post.frontmatter.date}
            </p>
            <p
              style={{
                ...scale(-1 / 5),
                display: 'block',
                color: COLOR_PALLETE.LIGHTER_BLACK,
                marginBottom: 0,
                marginTop: 0,
                marginLeft: '10px',
                marginRight: '10px',
              }}
            >
              • {post.timeToRead} min read
            </p>
          </div>
          {tags ? <TagList tags={tags} /> : null}
        </BlogInfo>
        <div
          className="blog-content"
          dangerouslySetInnerHTML={{ __html: post.html }}
        />

        <Share title={post.frontmatter.title} tags={post.frontmatter.tags} />
        <hr
          style={{
            marginBottom: rhythm(1),
          }}
        />

        <ul
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            listStyle: 'none',
            padding: 0,
          }}
        >
          {previous && (
            <li>
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            </li>
          )}

          {next && (
            <li>
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            </li>
          )}
        </ul>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
        siteUrl
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt
      html
      timeToRead
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        tags
        cover {
          childImageSharp {
            resolutions(width: 256, height: 256) {
              width
              height
              src
              srcSet
            }
          }
        }
      }
    }
  }
`
