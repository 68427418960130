import React from 'react'
import styled from 'react-emotion'
import { take } from 'lodash'

import TwitterIcon from '../assets/icons/twitter.svg'
import RedditIcon from '../assets/icons/reddit.svg'
import LinkedInIcon from '../assets/icons/linkedin.svg'
import { COLOR_PALLETE } from '../utils/colors'

const ShareContainer = styled('div')`
  display: flex;
  flex-direction: column;
  color: ${COLOR_PALLETE.LIGHTER_BLACK};
`

const ContainerHeading = styled('h4')`
  align-self: center;
  text-transform: capitalize;
`

const ShareLinks = styled('ul')`
  display: flex;
  justify-content: center;
  & li {
    list-style: none;
    height: 24px;
    width: 24px;
    cursor: pointer;
    transition: 0.2s;
  }
  & li:not(:first-child) {
    margin-left: 20px;
  }
  & li:hover {
    opacity: 0.6;
  }
  & li a {
    box-shadow: none;
  }
  & li svg {
    fill: ${COLOR_PALLETE.PRIMARY};
  }
`

const Share = ({ title, tags }) => {
  let HASH_TAG_LIMIT = 3
  let url
  if (typeof window !== 'undefined' && window) {
    url = window.location.href
  }

  // TODO: convert into function
  let tweetText = `Read "${title}"&via=kartik2406&url=${url}&hashtags=${take(
    tags,
    HASH_TAG_LIMIT
  ).join(',')}`

  let redditUrl = `https://reddit.com/submit?url=${url}&title=${title}`

  //TODO: Card cover image with differnet resolution  https://www.linkedin.com/help/linkedin/answer/46687/making-your-website-shareable-on-linkedin?lang=en
  let linkedInUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
    url
  )}&title=${encodeURIComponent(title)}&source=code yoda`
  return (
    <ShareContainer>
      <ContainerHeading> Share on</ContainerHeading>
      <ShareLinks>
        <li>
          <a
            target="_blank"
            href={`https://twitter.com/intent/tweet?text=${tweetText}`}
          >
            <TwitterIcon />
          </a>
        </li>
        <li>
          <a target="_blank" href={redditUrl}>
            <RedditIcon />
          </a>
        </li>
        <li>
          <a target="_blank" href={linkedInUrl}>
            <LinkedInIcon />
          </a>
        </li>
      </ShareLinks>
    </ShareContainer>
  )
}

export default Share
